@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lato';
  src: url('./assets/font/Lato/Lato-Regular.ttf');
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('./assets/font/Lato/Lato-Bold.ttf');
}

.triangle-top-blue:after {
  content: url(./assets/img/layout/triangle-top-blue.svg);
  position: absolute;
  top: -70px;
  left: 5vw;
  z-index: 1;
}

.nav-triangle-wrapper {
  height: 1.5vw;
}

.triangle.nav-triangle {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 40px;
  top: 0px;
  display: block;
}

.btn {
  transition: all 0.3s ease !important;
}

#present {
  scale: 1;
  transition: scale 250ms ease-in-out;
}
#present:hover {
  animation: shake 350ms 0s 4 ease-in-out;
  scale: 1.1;
  transition: scale 250ms ease-in-out;
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
